var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Contract-templateForm" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              footerShow: false,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  _c("div", { staticClass: "explain" }, [
                    _vm._v(
                      "说明：合同正文中，请避免使用“ $ {+'文字'+ }$”格式。"
                    ),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "模板名称",
                        "label-width": "78",
                        rules: [
                          {
                            required: true,
                            message: "请输入名称",
                            trigger: "blur",
                          },
                        ],
                        prop: "moduleName",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { placeholder: "请输入名称", maxlength: 30 },
                        model: {
                          value: _vm.form.moduleName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "moduleName", $$v)
                          },
                          expression: "form.moduleName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.accountType !== "101",
                          expression: "accountType!=='101'",
                        },
                      ],
                      attrs: {
                        label: "选择项目",
                        "label-width": "78",
                        rules: [
                          {
                            required: _vm.accountType !== "101",
                            message: "请选择所属项目",
                            trigger: "blur",
                          },
                        ],
                        prop: "communityId",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: { placeholder: "查询所属项目" },
                            model: {
                              value: _vm.form.communityId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "communityId", $$v)
                              },
                              expression: "form.communityId",
                            },
                          },
                          "v-select2",
                          _vm.communityParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "复制关键数据标签" } },
                [
                  _c("span", { staticClass: "click-hint" }, [
                    _vm._v("单击标签即可复制；并将其粘贴至合同的相应位置。"),
                  ]),
                  _c(
                    "span",
                    { staticClass: "link", on: { click: _vm.goVideo } },
                    [_vm._v("示例")]
                  ),
                  _vm._l(_vm.standardList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "label-content" },
                      [
                        _c("div", { staticClass: "left-title" }, [
                          _vm._v(_vm._s(item.labelTypeName) + "："),
                        ]),
                        _c(
                          "div",
                          { staticClass: "right-content" },
                          _vm._l(item.items, function (child, cIndex) {
                            return _c(
                              "div",
                              { key: cIndex },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: child.forExample,
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "right-item",
                                        on: {
                                          click: function ($event) {
                                            _vm.copeValue(
                                              _vm.getRealItemName(
                                                child.contractCommonName
                                              )
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getRealItemName(
                                              child.contractCommonName
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    )
                  }),
                  _c("div", { staticClass: "other-content" }, [
                    _c("div", { staticClass: "other-head" }, [
                      _c("div", { staticClass: "left-title" }, [
                        _vm._v("自定义数据："),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "add-btn",
                          on: { click: _vm.addTemplate },
                        },
                        [
                          _c("div", { staticClass: "add-icon" }, [_vm._v("+")]),
                          _c("div", [_vm._v("添加")]),
                        ]
                      ),
                      _c("div", { staticClass: "other-head-hint" }, [
                        _vm._v(
                          "若上述数据不满足实际合同内容，可自定义添加数据标签"
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "other-list" },
                      _vm._l(_vm.form.sepLabelList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "other-item",
                            on: {
                              click: function ($event) {
                                _vm.copeValue(
                                  _vm.getRealItemName(item.labelName)
                                )
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "other-item-head" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.getRealItemName(item.labelName)) +
                                  "\n                "
                              ),
                            ]),
                            _c("div", { staticClass: "other-item-bottom" }, [
                              _c("div", { staticClass: "btm-left-content" }, [
                                _c("img", {
                                  staticClass: "btm-left-img",
                                  attrs: { src: _vm.typeImage(item.dataType) },
                                }),
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.typeText(item.dataType)) +
                                    "\n                  "
                                ),
                              ]),
                              _c("div", { staticClass: "btn-right-content" }, [
                                _c("img", {
                                  staticClass: "btm-right-icon",
                                  attrs: {
                                    src: require("./images/tree_redact.png"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.editItem(item)
                                    },
                                  },
                                }),
                                _c("img", {
                                  staticClass: "btm-right-icon",
                                  attrs: {
                                    src: require("./images/tree_delete.png"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteItem(index)
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "col2-block",
                { attrs: { title: "合同模板" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "上传合同",
                        "label-width": "78",
                        rules: [
                          {
                            required: true,
                            message: "请上传合同",
                            trigger: "blur",
                          },
                        ],
                        prop: "moduleFileList",
                      },
                    },
                    [
                      _c("v-uploader", {
                        attrs: {
                          onlyImage: false,
                          beforeUpload: _vm.beforeUpload,
                          action: _vm.uploadURL,
                          fileList: _vm.form.moduleFileList,
                          fileSize: 10,
                        },
                        on: {
                          "update:fileList": function ($event) {
                            return _vm.$set(_vm.form, "moduleFileList", $event)
                          },
                          "update:file-list": function ($event) {
                            return _vm.$set(_vm.form, "moduleFileList", $event)
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "tip",
                            fn: function () {
                              return [
                                _c("span", { staticClass: "add-hint" }, [
                                  _vm._v(
                                    "请上传已添加数据标签的合同文件（格式：.docx）,文件大小限制10MB；\n请使用Word或者WPS将文件格式转化为.docx。"
                                  ),
                                ]),
                                _c(
                                  "v-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.fileUrl,
                                        expression: "fileUrl",
                                      },
                                    ],
                                    on: { click: _vm.downloadFile },
                                  },
                                  [_vm._v("下载合同")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isAdd,
                          expression: "!isAdd",
                        },
                      ],
                      attrs: {
                        label: "合同状态",
                        "label-width": "78",
                        rules: [
                          {
                            required: !_vm.isAdd,
                            message: "请选择合同状态",
                            trigger: "blur",
                          },
                        ],
                        prop: "moduleStatus",
                      },
                    },
                    [
                      _c("checkbox-plus", {
                        attrs: {
                          type: "radio",
                          options: _vm.vCheckoptions,
                          value: _vm.form.moduleStatus,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "moduleStatus", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", [
            _c("div", { staticClass: "footer-content" }, [
              _c(
                "div",
                { staticClass: "footer-save", on: { click: _vm.submit } },
                [_vm._v("\n            保存\n          ")]
              ),
              _c(
                "div",
                { staticClass: "footer-input", on: { click: _vm.testInput } },
                [_vm._v("\n            模拟输入\n          ")]
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("template-dialog", {
        attrs: {
          visible: _vm.dialogVisible,
          initData: _vm.dialogInitData,
          isAdd: _vm.isDialogAdd,
          otherNameList: _vm.otherNameList,
        },
        on: { "update:visible": _vm.updateDialogVisible, save: _vm.saveLabel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }