var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.innerVisible, title: _vm.title },
      on: {
        "update:visible": function ($event) {
          _vm.innerVisible = $event
        },
        close: _vm.onClose,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("v-button", {
                staticStyle: { "margin-right": "10px" },
                attrs: { text: "取消", type: "default" },
                on: { click: _vm.cancel },
              }),
              _c("v-button", {
                attrs: { text: "确定" },
                on: { click: _vm.save },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "form-panel",
        { ref: "formDialogPanel", attrs: { form: _vm.form, hasHeader: false } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "数据名称",
                "label-width": "78",
                prop: "labelName",
                rules: [
                  {
                    required: true,
                    message: "数据名称不能为空",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("v-input", {
                attrs: { width: 300, maxlength: 20 },
                model: {
                  value: _vm.form.labelName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "labelName", $$v)
                  },
                  expression: "form.labelName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "数据类型",
                "label-width": "78",
                rules: [
                  {
                    required: true,
                    message: "请选择数据类型",
                    trigger: "blur",
                  },
                ],
                prop: "dataType",
              },
            },
            [
              _c("checkbox-plus", {
                attrs: {
                  type: "radio",
                  options: _vm.vCheckoptions,
                  value: _vm.form.dataType,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.form, "dataType", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.dataType === "0",
                  expression: "form.dataType === '0'",
                },
              ],
              staticClass: "margin-bottom-8",
              attrs: {
                label: "数量限制",
                "label-width": "78",
                rules: [
                  {
                    required: _vm.form.dataType === "0",
                    message: "请选择数据类型",
                    trigger: "blur",
                  },
                ],
                prop: "labelValueSize",
              },
            },
            [
              _c("v-input-number", {
                attrs: { max: 999, width: 300, min: 1 },
                model: {
                  value: _vm.form.labelValueSize,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "labelValueSize", $$v)
                  },
                  expression: "form.labelValueSize",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.dataType === "0",
                  expression: "form.dataType === '0'",
                },
              ],
              staticClass: "hint botom-24px",
            },
            [
              _vm._v(
                "为避免创建合同时，该数据输入过多内容，你可以限定输入字符数量"
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注说明", "label-width": "78" } },
            [
              _c("v-textarea", {
                attrs: { maxlength: 100, width: 300 },
                model: {
                  value: _vm.form.labelRemarks,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "labelRemarks", $$v)
                  },
                  expression: "form.labelRemarks",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "hint bottom-12px" }, [
            _vm._v(
              "在创建一份合同时，“备注说明”会展示给操作人员并帮助其更好的理解数据含义或者数据输入要求。"
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }