<template>
  <div class="Contract-templateForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
      :footerShow="false"
    >
      <col2-detail>
        <col2-block title="基础信息">
          <div class="explain">说明：合同正文中，请避免使用“ $ {+'文字'+ }$”格式。</div>
          <el-form-item label="模板名称" label-width="78"   :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]" prop="moduleName">
            <v-input v-model="form.moduleName" placeholder="请输入名称" :maxlength="30" />
          </el-form-item>

          <el-form-item label="选择项目" label-width="78"   :rules="[{ required: accountType!=='101', message: '请选择所属项目', trigger: 'blur' }]"
             prop="communityId" v-show="accountType!=='101'">
           <v-select2 v-model="form.communityId" v-bind="communityParams" placeholder="查询所属项目"/>
          </el-form-item>
        </col2-block>
         <col2-block title="复制关键数据标签">
          <span class="click-hint">单击标签即可复制；并将其粘贴至合同的相应位置。</span>
          <span class="link" @click="goVideo">示例</span>
          <div v-for="(item,index) in standardList" :key="index" class="label-content">
            <div class="left-title">{{item.labelTypeName}}：</div>
            <div class="right-content">
              <div v-for="(child,cIndex) in item.items" :key="cIndex">
                <el-tooltip :content="child.forExample" placement="bottom">
                  <el-button class="right-item" @click="copeValue(getRealItemName(child.contractCommonName))">{{getRealItemName(child.contractCommonName)}}</el-button>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="other-content">
            <div class="other-head">
              <div class="left-title">自定义数据：</div>
              <div class="add-btn" @click="addTemplate">
                <div class="add-icon">+</div>
                <div>添加</div>
              </div>
              <div class="other-head-hint">若上述数据不满足实际合同内容，可自定义添加数据标签</div>
            </div>
            <div class="other-list">
              <div class="other-item" v-for="(item,index) in form.sepLabelList" :key="index" @click="copeValue(getRealItemName(item.labelName))">
                <div class="other-item-head">
                  {{getRealItemName(item.labelName)}}
                </div>
                <div class="other-item-bottom">
                  <div class="btm-left-content">
                    <img :src="typeImage(item.dataType)" class="btm-left-img"/>
                    {{typeText(item.dataType)}}
                  </div>
                   <div class="btn-right-content">
                    <img src="./images/tree_redact.png" class="btm-right-icon" @click.stop="editItem(item)"/>
                    <img src="./images/tree_delete.png" class="btm-right-icon" @click.stop="deleteItem(index)"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
         </col2-block>
         <col2-block title="合同模板">
          <el-form-item label="上传合同" label-width="78"  :rules="[{ required: true, message: '请上传合同', trigger: 'blur' }]" prop="moduleFileList">
             <v-uploader :onlyImage="false" :beforeUpload="beforeUpload" :action="uploadURL" :fileList.sync="form.moduleFileList" :fileSize="10" >
                <template #tip>
                  <span class="add-hint">请上传已添加数据标签的合同文件（格式：.docx）,文件大小限制10MB；
请使用Word或者WPS将文件格式转化为.docx。</span>
                 <v-button @click="downloadFile" v-show="fileUrl">下载合同</v-button>
                </template>
              </v-uploader>
          </el-form-item>
           
           <el-form-item label="合同状态" label-width="78"  :rules="[{ required: !isAdd, message: '请选择合同状态', trigger: 'blur' }]" 
                prop="moduleStatus" v-show="!isAdd">
              <checkbox-plus type="radio" :options="vCheckoptions" :value.sync="form.moduleStatus" />
          </el-form-item>
        </col2-block>
      </col2-detail>
      <div >
        <div class="footer-content">
          <div class="footer-save" @click="submit">
            保存
          </div>
           <div class="footer-input" @click="testInput">
            模拟输入
          </div>
        </div>
      </div>
    </form-panel>
    <template-dialog @update:visible="updateDialogVisible" @save="saveLabel"
      :visible="dialogVisible" :initData="dialogInitData" :isAdd="isDialogAdd"
      :otherNameList = "otherNameList"></template-dialog>
  </div>
</template>

<script>
import { editURL, queryURL, uploadURL, commonTemplateURL } from './api'
import { communityParams } from 'common/select2Params'
import { setStatusOps } from './map'
import { vUploader } from 'components/control'
import { Col2Detail, Col2Block, CheckboxPlus } from 'components/bussiness'

import TemplateDialog from './TemplateDialog'
import { sessionStorageHelper,downloadHelper } from 'common/utils'
export default {
  name: 'Contract-templateForm',
  components: {
    vUploader,
    Col2Detail,
    Col2Block,
    CheckboxPlus,
    TemplateDialog,
  },
  data () {
    return {
      width: 200,
      uploadURL,
      statusOps: setStatusOps(),
      communityParams,
      form: {
        communityId: undefined,
        id: '',
        moduleName: '',
        moduleStatus: '1',
        sepLabelList: [],
        moduleFile: {},
        moduleFileList: []
      },
      standardList: [],
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: ''
      },
      vCheckoptions: [
        {
          label: '开启',
          value: '1'
        }, {
          label: '关闭',
          value: '0'
        }
      ],
      isDialogAdd: false,
      dialogInitData: {},
      dialogVisible: false,
      commonListOne:[],
      isAdd:false,
      accountType:'',
      isTestInputReturn: false
    }
  },
  computed: {
    otherNameList(){
      return [...this.commonListOne.map(e=>e.contractCommonName),...this.form.sepLabelList.map(e=>e.labelName)] 
    },
    fileUrl(){
      if (this.form.moduleFileList.length > 0) {
        return this.form.moduleFileList[0].url
      }
    },
    filename(){
       if (this.form.moduleFileList.length > 0) {
        return this.form.moduleFileList[0].name
      }
    }
      
  },
  beforeRouteEnter (to, from, next) {
    // ...
    next(vm=>{
      if(from.name === 'contractManageForm'){
        let data = sessionStorageHelper.getItem('contractManageForm')
        vm.isTestInputReturn = true
        vm.form =  data
        vm.standardList = sessionStorageHelper.getItem('standardList')
        vm.commonListOne = sessionStorageHelper.getItem('commonListOne')
      }
      vm.init()
    })
  },
  methods: {
    init(){
      const { id } = this.$route.query
      this.submitConfig.submitUrl = editURL
      if (id !== undefined) {
        this.$setBreadcrumb('编辑')
        this.isAdd = false
        if(!this.isTestInputReturn){
          this.$refs.formPanel.getData({ id })
        }
      } else {
        this.$setBreadcrumb('新增')
        this.getCommonTemplate()
        this.isAdd = true
      }
      let userInfo = this.$store.state.userInfo
      this.accountType = userInfo.userType
      if(this.accountType === '101'){
        this.form.communityId = userInfo.communityId
      }
    },
    getRealItemName (name) {
      return '${' + name + '}$'
    },
    copeValue (value) {
      let el = document.createElement('input')
      el.value = `  ${value}  `
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      this.$message({
        message: '复制成功',
        type: 'success',
        duration:1000,
      })
      document.body.removeChild(el)
    },
    // 获取通用模板
    async getCommonTemplate () {
      let { data, status } = await this.$axios.get(commonTemplateURL)
      if (status === 100) {
        this.dealCommonList(data)
      }
    },
    dealCommonList (data) {
      this.commonListOne = data
      let standardList = []
      data.forEach(e => {
        let item = standardList.find(s =>
          s.labelType === e.labelType && s.labelTypeName === e.labelTypeName
        )
        if (item) {
          item.items.push(e)
        } else {
          standardList.push({ ...e, items: [e] })
        }
      })
      this.standardList = standardList
    },
    update (data) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
      if (this.form.sepLabelList) {
        this.form.sepLabelList.forEach(e => {
          e.dataType = String(e.dataType)
        })
      }
     
      this.form.moduleStatus = String(this.form.moduleStatus)
      let file = data.moduleFile
      if(data.moduleFile){
        file.name = file.moduleFileName
        file.url = file.moduleFileUrl
        file.isOther = true
      }
      file.percentage = false
      this.form.moduleFileList = [file]
      this.dealCommonList(data.commonLabelList)
    },
    addTemplate () {
      if(this.form.sepLabelList.length >= 20){
        this.$message('自定义数据不允许超过20个')
        return
      }
      this.dialogVisible = true
      this.isDialogAdd = true
      this.dialogInitData = {}
    },

    updateDialogVisible () {
      this.dialogVisible = false
      // this.dialogInitData = {}
    },
    saveLabel (item) {
      if (this.isDialogAdd) {
        this.form.sepLabelList.push(item)
      } else {
        let index = this.form.sepLabelList.indexOf(this.dialogInitData)
        this.form.sepLabelList.splice(index, 1, item)
      }
    },
    deleteItem (index) {
      this.form.sepLabelList.splice(index, 1)
    },
    editItem (item) {
      this.dialogVisible = true
      this.isDialogAdd = false
      this.dialogInitData = item
    },
    submitBefore (data) {
      if (!data.id) delete data.id
      return true
    },
    submit () {
      if (this.form.moduleFileList.length > 0) {
        this.form.moduleFile = { ...this.getNetFileObj(), ...this.form.moduleFileList[0] }
      }
      this.$refs.formPanel.submitClick()
    },
    getNetFileObj(){
        let file = {}
        let exitFile = this.form.moduleFileList[0]
        file.moduleFileName = exitFile.name
        file.moduleFileUrl = exitFile.url
        file.otherFlag = exitFile.isOther ? 1:0
        return file
    },
    testInput () {
      if(this.form.moduleFileList.length === 0){
        this.$message("请先上传合同模板")
        return
      }
      // 存入模板文件
      sessionStorageHelper.setItem('fileInfo',this.getNetFileObj())
      // 存入自定义标签数组
      sessionStorageHelper.setItem('sepLabelList',this.form.sepLabelList)

      sessionStorageHelper.setItem('contractManageForm',this.form)
      sessionStorageHelper.setItem('standardList',this.standardList)
      sessionStorageHelper.setItem('commonListOne',this.commonListOne)
      this.$router.push({
        name: 'contractManageForm',
        query: {
          isTestInput:1
        }
      })
    },
    typeImage (type) {
      if (type === '0') {
        return require('./images/input_icon.png')
      } else {
        return require('./images/choose_date.png')
      }
    },
    typeText (type) {
      if (type === '0') {
        return '文本输入'
      } else {
        return '日期选择'
      }
    },
    // Doc上传前的处理
    beforeUpload (file) {
      if(file.name.indexOf('.docx')===-1){
        this.$message('只支持docx格式文件')
        return false
      }
      return true
    },
    downloadFile(){
      if(this.fileUrl ){
        downloadHelper.downloadFile({
          data:this.fileUrl,
          fileName:this.filename
        })
      } 
    },
    goVideo(){
      window.open('https://happyv20.uama.com.cn/app/ly/htmb.mp4')
    }
  }
}
</script>
<style scoped lang='scss'>
  .explain{
    width:387px;
    height:40px;
    background:rgba(255,255,255,1);
    box-shadow:0px 0px 8px 0px rgba(244,75,30,0.5);
    border-radius:4px;
    font-size: 14px;
    line-height: 40px;
    color:#333;
    text-align: center;
    margin:16px 0 20px 5px;
  }
  .label-content{
    min-height: 60px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    .left-title{
      height: 60px;
      line-height: 60px;
      color: #333;
      width: 78px;
      text-align: right;
    }
    .right-content{
      flex:1;
      box-sizing: border-box;
      padding: 10px 15px 0 15px;
      display: flex;
      background: #F2F3F7;
      flex-wrap: wrap;
      .right-item{
        height: 40px;
        min-width: 200px;
        margin-right: 10px;
        margin-bottom: 10px;
        color:#4E8ACC;
        background:white;
        box-shadow:0px 2px 4px 0px rgba(0,0,0,0.1);
        border-radius:20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .other-content{
    display: flex;
    flex-direction: column;
    .other-head{
      height: 40px;
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      .left-text{
        width:78px;
        color: #333;
        text-align: right;
        line-height: 40px;
      }
      .add-btn{
        width:200px;
        height:40px;
        background:rgba(248,248,248,1);
        border-radius:20px;
        opacity:0.8;
        border:2px dashed rgba(221,221,221,1);
        display: flex;
        color: #1B8CF2;
        font-size: 14px;
        align-items: center;
        justify-content: center;
        .add-icon{
          font-weight: bold;
          font-size: 18px;
          margin-right: 5px;
        }
      }
      .other-head-hint{
        font-size: 12px;
        color: #1B8CF2;
        margin-left: 10px;
      }
    }
    .other-list{
      margin-left: 78px;
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      .other-item{
        width: 200px;
        height: 90px;
        background: #E3F0FF;
        display: flex;
        border-radius:4px;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 10px;
        margin:0 16px 16px 0;
        .other-item-head{
          color:#333;
          font-size: 14px;
          line-height:20px;
          cursor:pointer;
        }
        .other-item-bottom{
          display: flex;
          justify-content: space-between;
          color:#659BD6;
          font-size: 14px;
          line-height: 14px;
          .btm-left-content{
            .btm-left-img{
              height: 14px;
              width: 14px;
              margin-right: 3px;
            }
          }
          .btn-right-content{
            display: flex;
            .btm-right-icon{
              height: 20px;
              width: 20px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .bottom>>>.footer-button{
    padding-left: 0px;
  }
  .footer-content{
    height: 80px;
    display: flex;
    align-items: center;
    .footer-save{
      margin-left: 78px;
      height: 35px;
      width: 300px;
      background: #7ac943;
      color:white;
      size: 14px;
      text-align: center;
      line-height: 35px;
      border-radius:4px;
    }
    .footer-input{
      margin-left: 78px;
      height: 35px;
      width: 300px;
      border-radius:4px;
      border:1px solid #1B8CF2;
      color:#1B8CF2;
      size: 14px;
      text-align: center;
      line-height: 35px;
      margin-left: 10px;
    }
    
  }
  .click-hint{
    font-size:12px;  
    color:#666;
    margin-left: 10px;
  }
  .link{
      color:#1B8CF2;
      font-size:12px;
      margin-left: 10px; 
      text-decoration:underline ;
      cursor: pointer;
    }
    .add-hint{
      color:#1B8CF2;
      font-size:12px;
    }
</style>
