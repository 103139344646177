<template>
  <el-dialog :visible.sync="innerVisible" :title="title" @close="onClose">
    <form-panel :form="form" ref="formDialogPanel" :hasHeader="false">
      <el-form-item label="数据名称"  label-width="78"  prop="labelName"  :rules="[{ required: true, message:'数据名称不能为空', trigger: 'blur' }]">
        <v-input v-model="form.labelName" :width="300" :maxlength="20" />
      </el-form-item>
      <el-form-item label="数据类型" label-width="78"  :rules="[{ required: true, message: '请选择数据类型', trigger: 'blur' }]" prop="dataType">
        <checkbox-plus type="radio" :options="vCheckoptions" :value.sync="form.dataType" />
      </el-form-item>
       <el-form-item label="数量限制"  label-width="78" class="margin-bottom-8" v-show="form.dataType === '0'"
        :rules="[{ required: form.dataType === '0', message: '请选择数据类型', trigger: 'blur' }]" prop="labelValueSize">
        <v-input-number v-model="form.labelValueSize" :max="999" :width="300" :min="1"/>
      </el-form-item>
      <div class="hint botom-24px" v-show="form.dataType === '0'">为避免创建合同时，该数据输入过多内容，你可以限定输入字符数量</div>
      <el-form-item label="备注说明"  label-width="78">
        <v-textarea v-model="form.labelRemarks" :maxlength="100" :width="300"/>
      </el-form-item>
      <div class="hint bottom-12px">在创建一份合同时，“备注说明”会展示给操作人员并帮助其更好的理解数据含义或者数据输入要求。</div>
    </form-panel>
    <template #footer>
      <v-button text="取消" type="default" style="margin-right: 10px;" @click="cancel" />
      <v-button text="确定" @click="save" />
    </template>
  </el-dialog>
</template>
<script>
import { uploadURL } from 'common/api'
import { vUploader } from 'components/control'
import { CheckboxPlus } from 'components/bussiness'
export default {
  name: 'ServiceDialog',
  components: {
    vUploader,
    CheckboxPlus
  },
  props: {
    visible: Boolean,
    initData: Object,
    isAdd: Boolean,
    otherNameList:Array,
  },
  data () {
    return {
      innerVisible: false,
      uploadURL: `${uploadURL}?module=icon`,
      form: {
        labelName: '',
        id:'',
        dataType:"0",
        labelValueSize: 50,
        labelRemarks: '',
      },
       vCheckoptions:[
        {
         label: '文本输入',
         value: "0"
       }, {
         label: '日期选择',
         value: "1"
       }
      ],
      oldName:''
    }
  },
  computed: {
    title () {
      return this.isAdd ? '添加自定义数据标签' : '编辑自定义数据标签'
    }
  },
  watch: {
    visible: function (newValue) {
      this.innerVisible = newValue
      if (this.innerVisible) {
        if (this.initData) {
          // this.form = {...this.form,...this.initData}
          this.form.labelName = this.initData.labelName
          this.oldName = this.initData.labelName
          this.form.dataType = this.initData.dataType || "0"
          this.form.labelValueSize = this.initData.labelValueSize || 50
          this.form.labelRemarks = this.initData.labelRemarks || ''
          this.form.id = this.initData.id
          this.$nextTick(() => {
            this.$refs.formDialogPanel.clearValidate()
          })
        }
      }
    }
  },
  mounted () {
    if (this.initData) {
      this.form.labelName = this.initData.labelName
      this.oldName = this.initData.labelName
      this.form.dataType = this.initData.dataType || "0"
      this.form.labelValueSize = this.initData.labelValueSize || 50
      this.form.labelRemarks = this.initData.labelRemarks || ''
      this.form.id = this.initData.id
      // this.$refs.formDialogPanel.resetFields()
    }
  },
  methods: {
    onClose () {
      this.innerVisible = false
      this.$emit('update:visible', this.innerVisible)
    },
    cancel () {
      this.innerVisible = false
      this.$emit('update:visible', this.innerVisible)
    },
    save () {
      let valid = this.$refs.formDialogPanel.validate()
      if (!valid) {
        return
      }
      let setList = new Set(this.otherNameList)
      if(this.oldName){
        if(this.form.labelName !== this.oldName){
          if(setList.has(this.form.labelName)){
            this.$message('数据名称已存在')
            return
          }
        }
      }else{
        if(setList.has(this.form.labelName)){
            this.$message('数据名称已存在')
            return
          }
      }
      if (valid) {
        this.innerVisible = false
        this.$emit('update:visible', this.innerVisible)
        this.$emit('save', {...this.form})
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .tips {
    color: rgba(255, 102, 72, 1);
    font-size: 12px;
  }
  .margin-bottom-8{
    margin-bottom: 8px;
  }
  .hint{
    margin-left:78px;
    color: #1B8CF2;
    font-size: 12px;
    width: 300px;
  }
  .botom-24px{
    margin-bottom: 24px;
  }
  .bottom-12px{
    margin-bottom: 12px;
    margin-top: 12px;
  }
</style>
